import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import WebFont from 'webfontloader';
import createReduxStore from './store/createReduxStore';
import firebase, { rrfConfig } from './store/firebaseConfig';
import * as serviceWorker from './serviceWorker';
import './css/index.css';
import App from './App';

WebFont.load({
  google: {
    families: ['Quattrocento Sans:300,400,700', 'sans- serif']
  }
});

const store = createReduxStore();
const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

// Google Analytics Tracking
ReactGA.initialize("UA-141755444-1");

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root'));
// Service worker for caching.
serviceWorker.unregister();