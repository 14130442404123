import { REGISTER } from '../action/AuthAction';

const initState = {
  register: {
    error: null
  }
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case REGISTER:
      return {
        ...state,
        register: {
          ...state.register,
          error: action.error
        }
      }
    default:
      return state;
  }
}

export default authReducer;