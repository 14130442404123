import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import qs from "query-string";
import $ from "jquery";
import "../../css/StudentSignUp.css";

require('dotenv').config()

const nodemailer = require('nodemailer');
var usrEmail = $("#Test-email").val();

const transporter = nodemailer.createTransport({
  service: 'gmail',
  auth: {
    user: '${email.env.EMAIL_User}',
    pass: '${email.env.EMAIL_PASS}',
  },
});

const mailOptions = {
  from: 'valxtechnologies@gmail.com',
  to: usrEmail,
  subject: 'Thank You - ValX',
  text:
  'Hello!\n\n' +
  'Thank you for completing the survey. The information provided will be used to help us determine our next step moving forward.\n\n' +
  'Rest assured. If we do end up using the information in a presentation with investors/incubators, only general information will be revealed. So things such as age group and the tallied up votes. Personal information such as names and e-mails will not be revealed.\n\n' + 
  'Thanks again,\n\n' + 
  ' - The ValX Team',
};

transporter.sendMail(mailOptions, function(error, response){
  if (error){
    console.error('There was an error', error);
  } else {
    console.log('Here is the response: ', response);
  }
});


class StudentSignUp extends Component {
  state = {
    email: ""
  };

  handleEmailChange = event => {
    this.setState({
      email: event.target.value
    });
  };

  componentDidMount = () => {
    const query = qs.parse(this.props.location.search);
    if (query.email && query.email !== "") {
      // Email is prefilled in the url
      this.setState({
        ...this.state,
        email: query.email
      });
    }

    // on submit from #Stdnt-form initiates StdntSubmit() function
    $("#studentSUForm").submit(function(e) {
      e.preventDefault();
      StdntSubmit();
    });

    //submits users email input
    function StdntSubmit() {
      //takes value of user email from textbox
      var usrFirst = $("#Test-fname").val();
      var usrLast = $("#Test-lname").val();
      var usrEmail = $("#Test-email").val();
      var usrAge = $("#Test-age").val();
      var usrStdnt = $("#Test-stdnt").prop("checked");
      var usrEmp = $("#Test-emp").prop("checked");
      var usrChoice = $("#Test-choice").val();
      var usrScale = $("#Test-scale").val();
      //if value is not empty send off the value to Google Spreadsheet Script
      if (usrEmail !== "") {
        var script_url =
          "https://script.google.com/macros/s/AKfycbxy8BKn65jzHtcyTZtkb5JC-EWrlXKUzgoQc8vsmMkLvlf9e3Y/exec";
        var url =
          script_url +
          "?callback=ctrlq&fname=" +
          usrFirst +
          "&lname=" +
          usrLast +
          "&email=" +
          usrEmail +
          "&age=" +
          usrAge +
          "&stdnt=" +
          usrStdnt +
          "&emp=" +
          usrEmp +
          "&choice=" +
          usrChoice +
          "&scale=" +
          usrScale +
          "&action=insert";
        $.ajax({
          crossDomain: true,
          url: url,
          method: "GET",
          dataType: "jsonp"
        });

        // reset / free up the value from array
        $("#studentSUForm")[0].reset();
        //Alert user
        alert("Thank You For Your Input!");
      } else {
        alert("Please try again!");
        return false;
      }
    }
  };

  render() {
    return (
      <div>
        <form className="studentSUForm" id="studentSUForm">
          <div className="registerFormSpacing">First Name</div>
          <input
            name="Stdnt-fname"
            className="inputText"
            type="firstName"
            id="Test-fname"
            placeholder=""
            required
          />
          <div className="registerFormSpacing">Last Name</div>
          <input
            name="Stdnt-lname"
            className="inputText"
            type="lastName"
            id="Test-lname"
            placeholder=""
            required
          />
          <div className="registerFormSpacing">Email</div>
          <input
            name="Stdnt-email"
            className="inputText"
            type="email"
            id="Test-email"
            placeholder=""
            value={this.state.email}
            required
            onChange={this.handleEmailChange}
          />
          <div className="registerFormSpacing">Age</div>
          <select
            value={this.state.value}
            onChange={this.handleChange}
            name="Stdnt-age"
            className="inputText"
            type="age"
            id="Test-age"
            required
          >
            <option value="16 - 24">16 - 24</option>
            <option value="25 - 32">25 - 32</option>
            <option value="33 - 50">33 - 50</option>
            <option value="50+">50+</option>
          </select>
          <div className="space">
            <label>
              <span className="registerFormSpacingspan">
                Are You a Student?
              </span>
              <input
                name="Stdnt-stdnt"
                className="inputText"
                type="checkbox"
                id="Test-stdnt"
                value="True"
              />
            </label>
            <label className="rightLabel">
              <span className="registerFormSpacingspan">Are You Employed?</span>
              <input
                name="Stdnt-emp"
                className="inputText"
                type="checkbox"
                id="Test-emp"
                value="True"
              />
            </label>
          </div>
          <div className="registerFormSpacing">
            Which problem do you find to be the bigger issue (Search Jobs, Build
            Qualifications or Both)?
          </div>
          <select
            value={this.state.value}
            onChange={this.handleChange}
            name="Stdnt-choice"
            className="inputText"
            type="choice"
            id="Test-choice"
            required
          >
            <option value=""></option>
            <option value="Search Jobs">Search Jobs</option>
            <option value="Build Qualifications">Build Qualifications</option>
            <option value="Both">Both</option>
          </select>
          <div className="registerFormSpacing">
            Out of a scale of 1 - 10, how much do you need this platform?
          </div>
          <select
            value={this.state.value}
            onChange={this.handleChange}
            name="Stdnt-scale"
            className="inputText"
            type="scale"
            id="Test-scale"
            required
          >
            <option value=""></option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
          <button className="homeButton" type="submit" onSubmit={this.sendMail}>
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default withRouter(StudentSignUp);
