import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './css/index.css';

import LandingPage1 from './components/landingPage';
import LandingPage2 from './components/landingPage2';
import Privacy from './components/Privacy';
import Register from './components/auth/Register';
import TermsAndConditions from './components/TermsAndConditions';
import Homepage from './components/Homepage';
import LogIn from './components/auth/LoginModal';

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/search-jobs" component={LandingPage1} />
          <Route exact path="/build-qualifications" component={LandingPage2} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/register" component={Register} />
          <Route path="/terms-and-conditions" component={TermsAndConditions} />
          <Route path="/login" component={LogIn} />
        </Switch>
      </Router>
    );
  }
}

export default App;