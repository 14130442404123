import firebase from '../firebaseConfig';

export const UPDATE_STUDENT_PROFILE = 'UPDATE_STUDENT_PROFILE';
export const REVIEW_CLUB = 'REVIEW_CLUB';
export const FOLLOW_CLUB = 'FOLLOW_CLUB';
export const UNFOLLOW_CLUB = 'UNFOLLOW_CLUB';

export const updateStudentProfile = (newProfile, uid) => {
  return (dispatch, getState) => {
    const firestore = firebase.firestore();
    // Check if the current user logged has the same uid
    // If not then no permission to update.
    if (firebase.auth().currentUser.uid === uid) {
      firestore.collection('users').doc(uid).update({ ...newProfile }).then(res => {
        dispatch({
          type: UPDATE_STUDENT_PROFILE,
          error: null
        });
      }).catch(err => {
        dispatch({
          type: UPDATE_STUDENT_PROFILE,
          error: err
        });
      })
    } else {
      dispatch({
        type: UPDATE_STUDENT_PROFILE,
        error: { message: 'You must login first.' }
      });
    }
  }
}

export const reviewClub = (clubUid, rating, review) => {
  return (dispatch, getState) => {
    const firestore = firebase.firestore();
    const { auth, profile } = getState().firebase;
    // Check if the user is logged in and role is STUDENT
    if (auth.uid && profile.role === 'STUDENT') {
      const reviewRecord = {
        student: auth.uid,
        club: clubUid,
        createdAt: firebase.firestore.Timestamp.now(),
        rating: rating,
        review: review
      };
      // Attach the created record to student and club
      const studentRef = firestore.collection('users').doc(auth.uid);
      const clubRef = firestore.collection('users').doc(clubUid);


      const addReviewStudent = (reviews) => {
        // Create new array if null
        if (!reviews) return [reviewRecord];
        const result = reviews.filter(r => r.club !== clubUid);
        result.push(reviewRecord);
        return result;
      }

      const addReviewClub = (reviews) => {
        if (!reviews) return {
          reviews: [reviewRecord],
          totalRating: reviewRecord.rating
        };
        // Calculate totalRating while looping.
        let ratings = 0;
        const array = reviews.filter(r => {
          if (r.student !== auth.uid) {
            ratings += r.rating;
            return true;
          }
          return false;
        });
        // Add new record
        array.push(reviewRecord);
        ratings += reviewRecord.rating;
        return {
          reviews: array,
          totalRating: ratings / array.length
        };
      }

      const updateFunc = async t => {
        const [studentDoc, clubDoc] = await Promise.all([
          t.get(studentRef), t.get(clubRef)
        ]);
        if (!studentDoc.exists || !clubDoc.exists)
          return Promise.reject({ message: "Student or club doesn't exist" });
        
        const studentReviews = studentDoc.data().reviews;
        const clubReviews = clubDoc.data().reviews;
        // Process reviews
        // This step check if a review by the user for this club exists
        // If true then update the old review
        // Else create a new review
        const newStudentReviews = addReviewStudent(studentReviews);
        const newClubResult = addReviewClub(clubReviews);
        t.update(clubRef, { 
          reviews: newClubResult.reviews,
          totalRating: newClubResult.totalRating
        });
        t.update(studentRef, { reviews: newStudentReviews });
        return Promise.resolve();
      }

      firestore.runTransaction(updateFunc).then(() => {
        dispatch({
          type: REVIEW_CLUB,
          error: null
        });
      }).catch(err => {
        console.log('here', err);
        dispatch({
          type: REVIEW_CLUB,
          error: err
        });
      })
    } else {
      dispatch({
        type: REVIEW_CLUB,
        error: { message: 'You must login first.' }
      });
    }
  }
}

export const followClub = (clubUid) => {
  return (dispatch, getState) => {
    const firestore = firebase.firestore();
    const { auth, profile } = getState().firebase;
    // Check if the user is logged in and role is STUDENT
    if (auth.uid && profile.role === 'STUDENT') {
      const followRecord = {
        student: auth.uid,
        club: clubUid,
        createdAt: firebase.firestore.Timestamp.now()
      };
      // Attach the created record to student and club
      const studentRef = firestore.collection('users').doc(auth.uid);
      const timelineRef = firestore.collection('timelines').doc(auth.uid).collection('posts');
      const clubRef = firestore.collection('users').doc(clubUid);
      firestore.collection('posts').where('poster', '==', clubUid).get()
        .then(snap => {
          const batch = firestore.batch();
          batch.update(studentRef, {
            followings: firebase.firestore.FieldValue.arrayUnion(followRecord)
          });
          batch.update(clubRef, {
            followers: firebase.firestore.FieldValue.arrayUnion(followRecord)
          })
          snap.docs.forEach(docSnap => {
            batch.set(timelineRef.doc(docSnap.id), {
              ...docSnap.data()
            });
          })
          batch.commit().then(() => {
            dispatch({
              type: FOLLOW_CLUB,
              error: null
            });
          }).catch(err => {
            dispatch({
              type: FOLLOW_CLUB,
              error: err
            });
          })
        });
    } else {
      dispatch({
        type: FOLLOW_CLUB,
        error: { message: 'You must login first.' }
      });
    }
  }
}

export const unfollowClub = (clubUid) => {
  return (dispatch, getState) => {
    const firestore = firebase.firestore();
    const { auth, profile } = getState().firebase;
    // Check if the user is logged in and role is STUDENT
    if (auth.uid && profile.role === 'STUDENT') {
      // Attach the created record to student and club
      const studentRef = firestore.collection('users').doc(auth.uid);
      const clubRef = firestore.collection('users').doc(clubUid);
      firestore.collection('timelines').doc(auth.uid).collection('posts')
        .where('poster', '==', clubUid).get().then(snap => {
          // Unfollow process:
          // Get the references of posts of this club on the student timelines.
          // Delete follow records in both student and club entry.
          // Delete the posts using the fetched references.
          const updateFunc = async t => {
            const [studentDoc, clubDoc] = await Promise.all([
              t.get(studentRef), t.get(clubRef)
            ]);
            if (!studentDoc.exists || !clubDoc.exists)
              return Promise.reject({ message: "Student or club doesn't exist" });
            t.update(studentRef, {
              followings: studentDoc.data().followings.filter(f => f.club !== clubUid)
            });
            t.update(clubRef, {
              followers: clubDoc.data().followers.filter(f => f.student !== auth.uid)
            });
            // Timeline clean up
            snap.docs.forEach(snapDoc => {
              t.delete(snapDoc.ref);
            });
            return Promise.resolve();
          }

          firestore.runTransaction(updateFunc).then(() => {
            dispatch({
              type: UNFOLLOW_CLUB,
              error: null
            })
          }).catch(err => {
            dispatch({
              type: UNFOLLOW_CLUB,
              error: err
            })
          })
        });
    } else {
      dispatch({
        type: UNFOLLOW_CLUB,
        error: { message: 'You must login first.' }
      });
    }
  }
}