import { UPDATE_STUDENT_PROFILE, FOLLOW_CLUB, UNFOLLOW_CLUB, REVIEW_CLUB } from '../action/StudentAction';

const initialState = {
  updateStudentProfile: {
    error: null
  },
  followClub: {
    success: false,
    error: null
  },
  unfollowClub: {
    success: false,
    error: null
  },
  isFollowingClub: {
    error: null,
    isLoaded: false,
    value: false
  }
}

const studentReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_STUDENT_PROFILE:
      return {
        ...state,
        updateStudentProfile: {
          error: action.error
        }
      }
    case FOLLOW_CLUB:
      return {
        ...state,
        followClub: {
          success: action.error === null,
          error: action.error
        }
      }
    case UNFOLLOW_CLUB:
      return {
        ...state,
        unfollowClub: {
          success: action.error === null,
          error: action.error
        }
      }
    case REVIEW_CLUB:
      return {
        ...state,
        reviewClub: {
          error: action.error
        }
      }
    default:
      return state;
  }
}

export default studentReducer;