import { UPDATE_CLUB_PROFILE, GET_CLUB_BY_UID, GET_CLUBS, WRITE_POST } from '../action/ClubAction';

const initialState = {
  updateClubProfile: {
    error: null
  },
  getClubByUid: {
    isLoaded: false,
    error: null,
    data: null
  },
  getClubs: {
    error: null,
    data: null
  },
  writePost: {
    error: null
  }
}

const clubReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CLUB_PROFILE:
      return {
        ...state,
        updateClubProfile: {
          error: action.error
        }
      }
    case GET_CLUB_BY_UID:
      return {
        ...state,
        getClubByUid: {
          isLoaded: true,
          error: action.error,
          data: action.payload
        }
      }
    case GET_CLUBS:
      return {
        ...state,
        getClubs: {
          error: action.error,
          data: action.payload
        }
      }
    case WRITE_POST:
      return {
        ...state,
        writePost: {
          error: action.error
        }
      }
    default:
      return state;
  }
}

export default clubReducer;