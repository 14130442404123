import firebase from '../firebaseConfig';

export const UPDATE_CLUB_PROFILE = 'UPDATE_CLUB_PROFILE';
export const GET_CLUB_BY_UID = 'GET_CLUB_BY_UID';
export const GET_CLUBS = 'GET_CLUBS';
export const WRITE_POST = 'WRITE_POST';

export const updateClubProfile = (newProfile, uid) => {
  return (dispatch, getState) => {
    const firestore = firebase.firestore();
    // Check if the club is current logged now.
    // If not then no permission to update the club info.
    if (firebase.auth().currentUser.uid === uid) {
      firestore.collection('users').doc(uid).update({ ...newProfile }).then(res => {
        console.log('update success');
        dispatch({
          type: UPDATE_CLUB_PROFILE
        });
      }).catch(err => {
        dispatch({
          type: UPDATE_CLUB_PROFILE,
          error: err
        });
      })
    } else {
      dispatch({
        type: UPDATE_CLUB_PROFILE,
        error: { message: 'You must login first.' }
      });
    }
  }
}

export const getClubByUid = (uid, force) => {
  return (dispatch, getState) => {
    // Check if the club data is already in the cache
    const cache = getState().club.getClubByUid;
    if (!force && cache.isLoaded && !cache.error && cache.data.uid === uid) {
      dispatch({
        type: GET_CLUB_BY_UID,
        payload: cache.data,
      });
      return;
    }

    const firestore = firebase.firestore();
    firestore.collection('users').doc(uid).get().then(doc => {
      let club = doc.data();
      if (club && club.role === 'CLUB') {
        club = {
          ...club,
          uid: doc.id
        };
        dispatch({
          type: GET_CLUB_BY_UID,
          payload: club,
        });
      } else {
        dispatch({
          type: GET_CLUB_BY_UID,
          error: { message: 'Empty' }
        });
      }
    }).catch(err => {
      dispatch({
        type: GET_CLUB_BY_UID,
        error: err
      });
    })
  }
}

export const getClubs = (startFromName) => {
  return (dispatch, getState) => {
    const firestore = firebase.firestore();
    // Convert name to uppercase to make it easier to search in Firestore
    // This is because Firestore only support simple search mechanics,
    // for more complex it is better off using a search engine such as ElasticSearch.
    const upperCased = startFromName.toUpperCase();
    const query = firestore.collection('users');
    query.where('role', '==', 'CLUB').where('clubName', '>=', upperCased).get().then(snapshot => {
      const clubs = [];
      if (!snapshot.empty) {
        snapshot.docs.forEach(doc => {
          const item = {
            ...doc.data(),
            uid: doc.id
          }
          clubs.push(item);
        });
      }
      dispatch({
        type: GET_CLUBS,
        payload: clubs,
        error: null
      });
    }).catch(err => {
      dispatch({
        type: GET_CLUBS,
        error: err
      });
    })
  }
}

export const writePost = (post) => {
  return (dispatch, getState) => {
    const firestore = firebase.firestore();
    const { auth, profile } = getState().firebase;
    // Check if the club is logged in
    if (auth.uid && profile.role === 'CLUB') {
      const postRecord = {
        createdAt: firebase.firestore.Timestamp.now(),
        poster: auth.uid,
        posterName: profile.clubName,
        content: post.content,
        commentCount: 0
      }
      firestore.collection('posts').add(postRecord).then(() => {
        dispatch({
          type: WRITE_POST,
          error: null
        });
      }).catch(err => {
        dispatch({
          type: WRITE_POST,
          error: err
        })
      })
    } else {
      dispatch({
        type: WRITE_POST,
        error: { message: 'You must login first.' }
      })
    }
  }
}