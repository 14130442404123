import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../css/Nav.css";
class SearchBar extends Component {
  static propTypes = {
    id: PropTypes.string,
    classes: PropTypes.shape({
      button: PropTypes.string,
      schoolInput: PropTypes.string,
      queryInput: PropTypes.string
    }),
    placeholders: PropTypes.shape({
      school: PropTypes.string,
      query: PropTypes.string
    }),
    history: PropTypes.object.isRequired
  };

  static defaultProps = {
    classes: {
      button: "",
      schoolInput: "",
      queryInput: ""
    },
    placeholders: {
      school: "School",
      query: "Enter program, interests, or keywords"
    }
  };

  state = {
    userInput: {
      school: "",
      query: ""
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    const queryString = this.state.userInput.query;
    // Alert if search string is empty
    if (!queryString || queryString === "") {
      alert("Please enter your search");
      return;
    }
    // Proceed to search page
    const encoded = encodeURI(queryString);
    this.props.history.push(`/search?name=${encoded}`);
  };

  handleInputChange = event => {
    const newInput = {
      ...this.state.userInput,
      [event.target.name]: event.target.value
    };
    this.setState({
      ...this.state,
      userInput: newInput
    });
  };

  handleNextField = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementsByName("query")[0].focus();
    }
  };

  clearInput = () => {
    this.setState({ queryString: "" });
  };

  render() {
    const { id, classes, placeholders } = this.props;
    console.log(id);
    console.log(classes.input);
    const { school, query } = this.state.userInput;

    return (
      <div id={id}>
        <form action="" method="get" onSubmit={this.handleSubmit}>
          <input
            type="text"
            name="query"
            value={query}
            className={classes.input}
            placeholder={placeholders.query}
            onChange={this.handleInputChange}
          />
          <input
            type="text"
            name="school"
            className={classes.school}
            placeholder={placeholders.school}
            onChange={this.handleInputChange}
            value={school}
            onKeyDown={this.handleNextField}
          />
          <button type="submit" className={classes.button}>
            Search
          </button>
        </form>
      </div>
    );
  }
}

export default SearchBar;
