import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Nav from './nav/Nav';
import Footer from './layouts/Footer';

class Homepage extends Component {

  componentDidMount() {
    ReactGA.pageview('/');
  }

  travel = (link) => {
    this.props.history.push(link);
  }

  render() {
    return <>
      <Nav />
      <div className="homeBody" style={{ paddingBottom: '0' }}>
        <div className="main">
          <div className="backgroundheader">
            <div className="sectionPosition">
              <div className="searchBar borderBackground">
                <h1 className="headerText">Your passions, your way</h1>

                <div
                  style={
                    { 
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between'
                    }
                  }
                >
                  <button className="homeButton" onClick={() => this.travel('/search-jobs')} style={{ padding: '2vw 2vh' }}>Search for jobs</button>
                  <button className="homeButton" onClick={() => this.travel('/build-qualifications')} style={{ padding: '2vw 2vh' }}>Build qualifications</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>;
  }

}

export default Homepage;