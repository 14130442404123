import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import authReducer from './AuthReducer';
import clubReducer from './ClubReducer';
import studentReducer from './StudentReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  club: clubReducer,
  student: studentReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer
});

export default rootReducer;