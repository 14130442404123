import React from 'react';
import PropTypes from 'prop-types';

import '../../css/Modal.css';

const Modal = ({ header, children }) => {
  return (
    <div className="modal">
      <header className="modal-header">
        {header}
      </header>
      <div className="modal-body">
        {children}
      </div>
    </div>
  )
}

Modal.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string
}

export default Modal;
