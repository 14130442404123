import React, { Component } from "react";
import ReactGA from 'react-ga';
import Nav from "../nav/Nav";
import Footer from "../layouts/Footer";

import "../../css/LandingPage.css";

class LandingPage extends Component {

  componentDidMount() {
    ReactGA.pageview('/search-jobs');
  }

  state = {
    email: ''
  }

  collapsableOpen() {
    let open = document.querySelector(".learnMoreOpen");
    let section = document.querySelector(".learnMoreSection");

    open.style.display = "none";
    section.style.display = "block";
  }

  collapsableClose() {
    let open = document.querySelector(".learnMoreOpen");
    let section = document.querySelector(".learnMoreSection");

    open.style.display = "block";
    section.style.display = "none";
  }

  handleEmailChange = (evt) => {
    this.setState({
      ...this.state,
      email: evt.target.value
    })
  }

  gotoSignup = () => {
    let url = '/register';
    if (this.state.email && this.state.email !== '') {
      url += `?email=${this.state.email}`;
    }
    this.props.history.push(url);
  }

  render() {
    return (
      <div>
        <Nav />
        <div className="homeBody">
          <div className="main">
            <div className="backgroundheader">
              <div className="sectionPosition">
                <div className="searchBar borderBackground">
                  <h1 className="headerText">Your passions, your way</h1>
                  <h2 className="subheaderText">
                    Search for jobs that match you
                </h2>
                  <input
                    value={this.state.email}
                    className="inputText"
                    placeholder="Enter email address"
                    onChange={this.handleEmailChange}
                  />
                  <button className="homeButton" onClick={this.gotoSignup}>Survey</button>
                </div>
              </div>
            </div>
          </div>

          {/* Anchor div to offset, without this, 
            the next section will be covered by the navbar when link is clicked. 
          */}
          <div id="learn" />

          <div className="sectionPosition">
            <h2>Closer to your dreams</h2>
            <div className="main2">
              <div className="homeDescription">
                <h3 className="headerText2">Unique search</h3>
                Search jobs based on your qualifications such as education,
                experience, skills, and certifications.
              </div>
              <div className="homeDescription">
                <h3 className="headerText2">Save time</h3>
                Spend less time scrolling through each job, instead find more
                jobs matched to you.
              </div>
              <div className="homeDescription">
                <h3 className="headerText2">Increase chances</h3>
                We understand the value behind each job application. Increase
                your chances for landing an interview.
              </div>
            </div>

            <button
              className="homeButton learnMoreOpen"
              onClick={this.collapsableOpen}
            >
              Learn more <i className="arrow down" />
            </button>
          </div>
          <div className="sectionPosition learnMoreSection">
            <hr />

            <div className="homeDescription2">
              <h2>Issue</h2>
              <h3>Current search engines are not tailored for you.</h3>
            </div>

            <hr />

            <div className="homeDescription2">
              <h2>Solution</h2>
              <h3>We know your worth. Find jobs that match you.</h3>
            </div>

            <button
              className="homeButton learnMoreClose"
              onClick={this.collapsableClose}
            >
              Learn less <i className="arrow up" />
            </button>
          </div>
          <hr />

          <div className="sectionPosition">
            <div className="homeDescription3">
              <h2>How it works</h2>
              <h3>
                <span>1</span> &nbsp;Setup your profile
              </h3>
              <h3>
                <span>2</span> Search for jobs
              </h3>
              <h3>
                <span>3</span> Jobs matched to your qualifications
              </h3>
              <button className="homeButton" onClick={this.gotoSignup}>
                Survey
                </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default LandingPage;
