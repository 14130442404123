import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const fbConfig = {
  apiKey: "AIzaSyALkt-4wkdQvM-8g9Ya8EsE9onKfNX7FG0",
  authDomain: "valx-web.firebaseapp.com",
  databaseURL: "https://valx-web.firebaseio.com",
  projectId: "valx-web",
  storageBucket: "valx-web.appspot.com",
  messagingSenderId: "1062579732906"
};

/** react-redux-firebase config. */
export const rrfConfig = {
  userProfile: 'users',
  attachAuthIsReady: true,
  useFirestoreForProfile: true,
  logErrors: process.env.NODE_ENV === 'development',
  logListenerError: process.env.NODE_ENV === 'development',
};

// Initialize firebase instance
firebase.initializeApp(fbConfig);
// Initialize other services on firebase instance
firebase.firestore();

export default firebase;