import React, { Component } from "react";
import ReactGA from 'react-ga';
import Nav from "../nav/Nav";
import Footer from "../layouts/Footer";
import StudentSignUp from "./StudentSignUp";

import "../../css/Register.css";

function disableAll() {
  let regForm = document.querySelector(".registerForm");
  let stuForm = document.querySelector(".registerStudentContent");
  let orgForm = document.querySelector(".registerOrgContent");
  let selection = document.querySelector(".registerBodyContent");

  regForm.style.display = "none";
  orgForm.style.display = "none";
  selection.style.display = "none";
  stuForm.style.display = "none";
}

function enableRegForm() {
  let regForm = document.querySelector(".registerForm");

  regForm.style.display = "block";
}

class Register extends Component {

  componentDidMount() {
    ReactGA.pageview('/register');
  }

  studentRegisterToggle() {
    disableAll();
    enableRegForm();

    let stuForm = document.querySelector(".registerStudentContent");

    stuForm.style.display = "block";
  }

  orgRegisterToggle() {
    disableAll();
    enableRegForm();

    let orgForm = document.querySelector(".registerOrgContent");

    orgForm.style.display = "block";
  }

  render() {
    return (
      <div>
        <Nav />
        <div className="registerBody">
          <div className="card registerBodyContent">
            <h2>Survey</h2>
            <StudentSignUp />
          </div>

          {/**    <div className="card registerBodyContent registerForm">
                        <div className="registerStudentContent">
                        Did you mean to click on <button onClick={this.orgRegisterToggle}>Club</button>?
                            <StudentSignUp />
                        </div>
                        <div className="registerOrgContent">
                        Did you mean to click on <button onClick={this.studentRegisterToggle}>Student</button>?
                            <ClubSignUp />
                        </div>
                    </div>
                    */}
        </div>
        <Footer />
      </div>
    );
  }
}

export default Register;
