import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import '../../css/Footer.css';
import facebook from '../../img/facebook.png';
import twitter from '../../img/twitter.png';
import instagram from '../../img/instagram.png';
import linkedin from '../../img/linkedin.png';

class Footer extends Component {
    isProblem2 = () => {
        return this.props.location.pathname === '/build-qualifications';
    }

    render() {
        const footerOverrideBackground = this.isProblem2() ? { backgroundColor: 'var(--main-colour)' } : {};
        return (
            <footer style={footerOverrideBackground}>
                <div className="footer-content">
                    <div className="footer-links">
                        <NavLink to="/" id="brand">ValX</NavLink>
                        <ul>
                            <li>
                                <NavLink to="/terms-and-conditions">Terms</NavLink>
                            </li>
                            <li>
                                <NavLink to="/privacy">Privacy</NavLink>
                            </li>
                            <li>
                                <NavHashLink smooth to="/#learn">Learn more</NavHashLink>
                            </li>
                            <li>
                                <NavLink to="/register">Sign up</NavLink>
                            </li>
                            <li>
                                <a to="/login">Log in</a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-social">
                        <span className="header">Connect with us</span>
                        <div className="footer-social-icons">
                            <a href="https://facebook.com">
                                <img src={facebook} alt='Facebook' />
                            </a>
                            <a href="https://instagram.com">
                                <img src={instagram} alt='Instagram' />
                            </a>
                            <a href="https://twitter.com">
                                <img src={twitter} alt='Twitter' />
                            </a>
                            <a href="https://linkedin.com/company/valxtech/about/">
                                <img src={linkedin} alt='LinkedIn' />
                            </a>
                        </div>
                    </div>
                </div>
                <p className="footer-text">&copy; ValX 2019. All rights reserved.</p>
            </footer>
        );
    }
}

export default withRouter(Footer);
