import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import Nav from '../nav/Nav';
import Modal from '../layouts/Modal';
import Footer from '../layouts/Footer';
import '../../css/LoginModal.css';

class LoginModal extends Component {
  state = {
    email: '',
    password: '',
    error: null
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: null });
    const credentials = { 
      email: this.state.email, 
      password: this.state.password 
    };
    const { firebase } = this.props;
    firebase.login(credentials).catch(error => {
      this.setState({ error });
    });
  }

  render() {
    if (this.props.isAuth) return <Redirect to='/profile' />
    return (
      <div>
        <Nav />
        <section className="content">
          <Modal header="Stay tuned!">
            <div>
              This portion of the site has been disabled.
            </div>

            <div>
            The log-in feature will be re-activated after the research phase has finished.
            </div>
            {this.state.error && (
              <h3>Error: {this.state.error.message}</h3>
            )}
          </Modal>
        </section>
        <Footer />
      </div>
    );
  }
}

const stateMapper = ({ firebase }) => {
  return {
    isAuth: !firebase.auth.isEmpty
  }
};

export default compose(
  connect(stateMapper),
  firebaseConnect()
)(LoginModal);