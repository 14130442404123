import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { getClubs } from "../../store/action/ClubAction";
import NavLinks from "./NavLinks";
import SearchBar from "../inputs/SearchBar";

import "../../css/Nav.css";

class Nav extends Component {
  burgerToggle = () => {
    let elements = document.querySelector("#navbar-mobile-content");
    if (elements.style.display === "flex") {
      elements.style.display = "none";
    } else {
      elements.style.display = "flex";
    }
  };

  handleInputChange = queryString => {
    // Do nothing if the string is empty.
    if (queryString !== "") {
      this.props.getClubs(queryString);
    }
  };

  handleSearchSubmit = (event, queryString) => {
    event.preventDefault();
    if (queryString !== "") {
      const encoded = encodeURI(queryString);
      this.props.history.push(`/search?name=${encoded}`);
    }
  };

  handleLogout = () => {
    this.props.firebase.logout();
  };

  isProblem2 = () => {
    return this.props.location.pathname === '/build-qualifications';
  }

  render() {
    const location = this.props.location.pathname !== "/";
    const { isAuth, authRole, searchResults } = this.props;
    const navbarOverrideBackground = this.isProblem2() ? { background: 'var(--main-colour)' } : {};
    return (
      <div>
        <nav id="navbar" style={navbarOverrideBackground}>
          <div id="navbar-brand">
            <NavLink exact to="/" className="companyName">
              ValX
            </NavLink>
          </div>

          {isAuth && location && (
            <SearchBar
              results={searchResults}
              onSubmit={this.handleSearchSubmit}
              onChange={this.handleInputChange}
              id="navbar-search"
            >
              Search clubs
            </SearchBar>
          )}

          <NavLinks
            isAuth={isAuth}
            isProblem2={this.isProblem2()}
            authRole={authRole}
            logoutHandler={this.handleLogout}
            burgerToggle={this.burgerToggle}
          />
        </nav>
      </div>
    );
  }
}

const stateMapper = ({ firebase, club }) => {
  return {
    isAuth: !firebase.auth.isEmpty,
    authRole: firebase.profile.role,
    searchResults: club.getClubs.data
  };
};

const dispatchMapper = dispatch => {
  return {
    getClubs: name => dispatch(getClubs(name))
  };
};

export default withRouter(
  compose(
    connect(
      stateMapper,
      dispatchMapper
    ),
    firebaseConnect()
  )(Nav)
);
