import firebase from '../firebaseConfig';

export const REGISTER = 'REGISTER';

export const register = (user) => {
  return (dispatch, getState) => {
    const firestore = firebase.firestore();
    // Create account with firebase.
    firebase.auth().createUserWithEmailAndPassword(user.email, user.password)
      .then(res => {
        // Purge email and password from user data.
        delete user.email;
        delete user.password;
        // The remaining data will be populated into the database.
        return firestore.collection('users').doc(res.user.uid).set({ ...user }).then(() => {
          dispatch({
            type: REGISTER,
            error: null
          });
        }).catch(err => {
          dispatch({
            type: REGISTER,
            error: err 
          });
        })
      })
  }
}