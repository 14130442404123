import React, { Fragment } from "react";
import ReactGA from 'react-ga';
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import "../../css/Nav.css";

const loginTracking = () => {
  ReactGA.event({
    category: 'User',
    action: 'Clicked Login on navbar'
  });
};

function NavLinks({ isAuth, isProblem2, authRole, logoutHandler, burgerToggle }) {
  const navbarActiveClassName = isProblem2 ? 'navbar-active-link2' : 'navbar-active-link';
  const linkPreAuth = (
    <Fragment>
      <NavLink exact to="/search-jobs" activeClassName={navbarActiveClassName}>
        Search jobs
      </NavLink>
      <NavLink exact to="/build-qualifications" activeClassName={navbarActiveClassName}>
        Build qualifications
      </NavLink>
      <NavLink exact to="/login" onClick={loginTracking} activeClassName={navbarActiveClassName}>
        Log in
      </NavLink>
      <NavLink exact to="/register" activeClassName={navbarActiveClassName}>
      Survey
      </NavLink>
    </Fragment>
  );

  const studentLinks = (
    <Fragment>
      <NavLink to="/user-profile" activeClassName={navbarActiveClassName}>
        Newsfeed
      </NavLink>
    </Fragment>
  );

  const linkPostAuth = (
    <Fragment>
      {authRole === "STUDENT" && studentLinks}
      <NavLink to="/profile" activeClassName={navbarActiveClassName}>
        Profile
      </NavLink>
      <NavLink to="/settings" activeClassName={navbarActiveClassName}>
        Settings
      </NavLink>
      <NavLink to="/" onClick={logoutHandler}>
        Logout
      </NavLink>
    </Fragment>
  );

  return (
    <Fragment>
      <div id="navbar-links-desktop">
        {isAuth && authRole ? linkPostAuth : linkPreAuth}
      </div>

      <div id="navbar-links-mobile">
        <div className="burgerToggle" onClick={burgerToggle}>
          <div className="hamburger" />
          <div className="hamburger" />
          <div className="hamburger" />
        </div>
        <div id="navbar-mobile-content">
          {isAuth && authRole ? linkPostAuth : linkPreAuth}
        </div>
      </div>
    </Fragment>
  );
}

NavLinks.propTypes = {
  isAuth: PropTypes.bool,
  isProblem2: PropTypes.bool,
  authRole: PropTypes.string,
  logoutHandler: PropTypes.func.isRequired,
  burgerToggle: PropTypes.func.isRequired
};

export default NavLinks;
