import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import reducer from './reducer/RootReducer';

const initialState = {}

// Redux DevTools to debug redux store - not needed for production.
const reduxDevCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  return createStore(
    reducer,
    initialState,
    reduxDevCompose(
      applyMiddleware(thunk)
    )
  )
}